import React from 'react';
import Countdown from 'react-countdown';
import { Link } from 'gatsby';
import EventSlider from 'react-slick';
import Layout from '../../../components/Layout';
import Copyright from '../../../components/Copyright';
import { getSeoConfig } from '../../../constants/defaultSeoProps';
import ThPartnerLogo from '../../../components/Header/brand-logo.svg';
import s from '../AwsLaEvent/Events.module.scss';
import ThLogo from '../AwsLaEvent/th-logo-white.svg';
import AwsLogo from '../AwsLaEvent/aws-logo.svg';
import AwsDarkLogo from '../AwsLaEvent/aws-dark-logo.svg';
import DateIcon from '../AwsLaEvent/date-icon.svg';
import LocationIcon from '../AwsLaEvent/map-icon.svg';
import Linkedin from '../../../assets/svg/linkedin.svg';
import Git from '../../../assets/svg/git.svg';
import StudiosImage from './usc-tower.jpg';
import LoungeImage from './usc-tower-dron-full.jpg';
import YorickImage from './yorick.png';
import AdilImage from './adil.png';
import GlobalIcon from './global-icon.svg';

const Completionist = () => <div className={s.countdown}>You are good to go!</div>;

const addLeadingZero = (value) => {
  return value < 10 ? `0${value}` : value;
};

const renderer = ({ days, hours, minutes, seconds, completed }) => {
  if (completed) {
    return <Completionist />;
  }
  return (
    <div className={s.countdown}>
      <div className={s.count}>
        <h2>{addLeadingZero(days)}</h2>
        <h3>days</h3>
      </div>
      <span>:</span>
      <div className={s.count}>
        <h2>{addLeadingZero(hours)}</h2>
        <h3>hours</h3>
      </div>
      <span>:</span>
      <div className={s.count}>
        <h2>{addLeadingZero(minutes)}</h2>
        <h3>mins</h3>
      </div>
      <span>:</span>
      <div className={s.count}>
        <h2>{addLeadingZero(seconds)}</h2>
        <h3>secs</h3>
      </div>
    </div>
  );
};

const seoParams = {
  ogImagePath: '/og-images/data-strategy-genai-business-growth.jpg',
  ogTitle:
    'Master Data Strategy for Business Growth: Interactive Workshop with Generative AI Insights | Tech Holding',
  ogDesc:
    'Learn the latest tools, techniques and best practices to harness the power of your data so that you can innovate with generative AI in your business.',
  ogPath: '/events/data-strategy-genai-business-growth',
};

const updatedSeo = getSeoConfig(seoParams);

// Takes UTC date and returns counter accordingly
const getUtcDate = (year, month, date, hours = 0, minutes = 0, seconds = 0) => {
  const utcDate = new Date(Date.UTC(year, month - 1, date, hours, minutes, seconds));
  return utcDate;
};

const settings = {
  dots: false,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 4000,
};

const DataStrategyGenAIBusinessGrowth = () => (
  <Layout
    currentPage="/events/data-strategy-genai-business-growth"
    seo={updatedSeo}
    title={updatedSeo.openGraph.title}
  >
    <div className={s.eventPage}>
      <div className={s.sliderWrapper} data-aos="fade-up" data-aos-delay="200">
        <div className={s.fullSlider}>
          <EventSlider {...settings}>
            <div className={s.slideImage}>
              <img src={StudiosImage} alt="" />
            </div>
            <div className={s.slideImage}>
              <img src={LoungeImage} alt="" />
            </div>
          </EventSlider>
        </div>
        <div className={s.hero}>
          <div className={s.container}>
            <div className={s.heroLogo}>
              <ThLogo />
              <span className={s.logoLine} />
              <AwsLogo />
            </div>
            <div className={s.eventSchedule}>
              <div className={s.flexSchedule}>
                <h1>
                  Master Data Strategy for Business Growth: Interactive Workshop with Generative AI
                  Insights
                </h1>
                <Countdown date={getUtcDate(2024, 6, 27, 20, 0, 0)} renderer={renderer} />
              </div>
              <div className={s.flex}>
                <div className={s.iconText}>
                  <DateIcon />
                  <span>June 27, 2024, 1:00 PM - 3:00 PM</span>
                </div>
                <div className={s.iconText}>
                  <LocationIcon />
                  <span>
                    <strong>USC Tower in DTLA</strong>
                  </span>
                </div>
                <div className={s.iconText}>
                  <GlobalIcon />
                  <span>Virtual / In Person</span>
                </div>
              </div>
            </div>
            <div className={s.registerButton}>
              <a href="#register">Register</a>
            </div>
          </div>
        </div>
      </div>

      <div className={s.eventMain} data-aos="fade-up" data-aos-delay="400">
        <div className={s.container}>
          <div className={s.eventContent}>
            <div className={s.eventMainContent}>
              <p>Is your business or team struggling to make the most out of its data?</p>
              <p>
                Nowadays, data is like gold. Your business might be sitting on a gold mine. But the
                gold mine is worthless if you don&apos;t have a strategy.
              </p>
              <p>
                Just like miners find, extract and refine gold – a data strategy involves
                discovering, integrating and analyzing data to uncover valuable insights. You need a
                solid data strategy in place to do cool stuff with generative AI.
              </p>
              <p>
                Don&apos;t fall behind – capitalize on the data and AI gold rush today. We&apos;ll
                show you how.
              </p>
              <p>
                Join us for an AWS interactive workshop where you&apos;ll get
                <b> expert advice on how to transform your raw data into gold. </b>
                Learn the latest tools, techniques and best practices to harness the power of your
                data so that you can innovate with generative AI in your business.
              </p>
              <p>Here&apos;s what you&apos;ll get:</p>
              <ul>
                <li>
                  <b>A hands-on demo of what a solid data strategy looks like. </b>
                  We will walk you through how to maximize your data&apos;s potential and improve
                  what you already do. Unearth opportunities with GenAI.
                </li>
                <li>
                  <b>A hands-on demo of Amazon Q - </b>
                  learn about how it can help your business. Enhance your employees creativity, get
                  new insights from your data or help your engineers innovate.
                </li>
                <li>
                  A valuable
                  <b> networking opportunity </b>
                  to connect with industry experts.
                </li>
                <li>
                  An exciting raffle for a chance to win a
                  <b> 2-4 week fully funded data strategy assessment!</b>
                </li>
              </ul>
              <p>This workshop is for you if:</p>
              <ul>
                <li>You want to learn how to prepare your data for generative AI</li>
                <li>You&apos;re looking to uncover new business avenues with your existing data</li>
                <li>You want expert feedback on your data strategy</li>
                <li>You want to be on top of the latest trends in data and AI</li>
              </ul>
              <p>Complimentary lunch will be provided for in-person attendees.</p>
              <p>
                <b>We have limited spots - reserve yours today.</b>
              </p>
            </div>

            <div className={s.speakersSection}>
              <h3>Event Speakers</h3>
              <div className={s.card}>
                <div className={s.speakersImg}>
                  <img src={YorickImage} alt="" />
                </div>
                <div className={s.speakersInfo}>
                  <div className={s.speakersTitle}>
                    <h3>Yorick Demichelis</h3>
                    <h4>Solutions Architect, Tech Holding</h4>
                  </div>
                  <div className={s.speakersBio}>
                    <p>
                      Yorick Demichelis is a seasoned Software Engineer and Solutions Architect with
                      experience in frontend, backend, infrastructure and software architecture. His
                      adaptability and expertise led him to design, build, and deploy scalable and
                      resilient services that have a high impact.
                    </p>
                    <p>
                      Yorick has been working with Tech Holding for 5 years and had the opportunity
                      to work with industry leaders such as Warner Bros, Walmart, and Westfield, as
                      well as with startups like BuildOps and TLYNT. He is based in Los Angeles,
                      California.
                    </p>
                  </div>
                  <div className={s.iconLinks}>
                    <Link
                      className={s.link}
                      to={'https://www.linkedin.com/in/yorickdemichelis/'}
                      target="_blank"
                    >
                      <Linkedin />
                    </Link>
                    <Link className={s.link} to={'https://github.com/ctrlaltdev'} target="_blank">
                      <Git />
                    </Link>
                  </div>
                </div>
              </div>
              <div className={s.card}>
                <div className={s.speakersImg}>
                  <img src={AdilImage} alt="" />
                </div>
                <div className={s.speakersInfo}>
                  <div className={s.speakersTitle}>
                    <h3>Adil Can</h3>
                    <h4>Sr. Solutions Architect, AWS</h4>
                  </div>
                  <div className={s.speakersBio}>
                    <p>
                      Adil Can is a Sr. Solutions Architect who specializes in delivering innovative
                      solutions to customers, accelerating their cloud adoption to realize value,
                      and allowing them to maximize their investment in AWS.
                    </p>
                    <p>
                      He has been working for AWS more than 4 years on several positions and a part
                      of Technical Field Community on Containers.
                    </p>
                    <p>
                      Before joining AWS, he worked for IBM, Veeam and Alcatel-Lucent. Adil holds an
                      MBA. He is based in California (US).
                    </p>
                  </div>
                  <div className={s.iconLinks}>
                    <Link
                      className={s.link}
                      to={'https://www.linkedin.com/in/adilcan/'}
                      target="_blank"
                    >
                      <Linkedin />
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className={s.sponsorsSection}>
              <h3>Sponsors</h3>
              <div className={s.flex}>
                <ThPartnerLogo />
                <AwsDarkLogo />
              </div>
            </div>
          </div>

          <div className={s.space} id="register">
            Register
          </div>

          <div className={s.eventForm}>
            <div
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: `<div id="mc_embed_shell" class="register-form disabled-form" style="display: block;">
                <div id="mc_embed_signup">
                   <form action="https://techholding.us18.list-manage.com/subscribe/post?u=12eacc3d78ee8cee150f4a09c&amp;id=6d2ac1e5cd&amp;f_id=005f16e7f0" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank">
                      <div id="mc_embed_signup_scroll">
                         <h3>Register Now</h3>
                         <div class="indicates-required"><span class="asterisk">*</span> indicates required</div>
                         <div class="mc-field-group input-group mc-radio-group">
                            <ul>
                                <li class="form-radio"><input type="radio" name="MEETINGTYP" id="mce-MEETINGTYP0" value="Virtual" disabled="disabled"><label for="mce-MEETINGTYP0">Virtual</label></li>
                                <li class="form-radio"><input type="radio" name="MEETINGTYP" id="mce-MEETINGTYP1" value="In Person" disabled="disabled"><label for="mce-MEETINGTYP1">In Person</label></li>
                            </ul>
                        </div>
                         <div class="mc-field-group">
                            <input type="text" name="FNAME" class="form-input required text" id="mce-FNAME" required="" placeholder="Enter First Name" value="" disabled="disabled">
                            <label class="form-label" for="mce-FNAME">First Name <span class="asterisk">*</span></label>
                        </div>
                         <div class="mc-field-group">
                            <input type="text" name="LNAME" class="form-input required text" id="mce-LNAME" required="" placeholder="Enter Last Name" value="" disabled="disabled">
                            <label class="form-label" for="mce-LNAME">Last Name <span class="asterisk">*</span></label>
                        </div>
                         <div class="mc-field-group">
                            <input type="text" name="COMPNAME" class="form-input required text" id="mce-COMPNAME" required="" placeholder="Enter Company Name" value="" disabled="disabled">
                            <label class="form-label" for="mce-COMPNAME">Company <span class="asterisk">*</span></label>
                        </div>
                         <div class="mc-field-group">
                            <input type="email" name="EMAIL" class="form-input required email" id="mce-EMAIL" required="" placeholder="Enter Work Email" value="" disabled="disabled">
                            <label class="form-label" for="mce-EMAIL">Work Email <span class="asterisk">*</span></label>
                        </div>
                         <div class="mc-field-group">
                            <input type="text" name="PHONE" class="form-input REQ_CSS" id="mce-PHONE" placeholder="Enter Phone Number" value="" disabled="disabled">
                            <label class="form-label" for="mce-PHONE">Phone Number </label>
                        </div>
                         <div hidden=""><input type="hidden" name="tags" value="2104086"></div>
                         <div id="mce-responses" class="clear foot">
                            <div class="response" id="mce-error-response" style="display: none;"></div>
                            <div class="response" id="mce-success-response" style="display: none;"></div>
                         </div>
                         <div aria-hidden="true" style="position: absolute; left: -5000px;">
                            /* real people should not fill this in and expect good things - do not remove this or risk form bot signups */
                            <input type="text" name="b_12eacc3d78ee8cee150f4a09c_6d2ac1e5cd" tabindex="-1" value="" disabled="disabled">
                         </div>
                         <div class="optionalParent">
                            <div class="clear foot">
                               <input type="submit" name="subscribe" id="mc-embedded-subscribe" class="button" value="Register" disabled="disabled">
                            </div>
                         </div>
                      </div>
                   </form>
                </div>
                <script type="text/javascript" src="//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js"></script><script type="text/javascript">(function($) {window.fnames = new Array(); window.ftypes = new Array();fnames[7]='MEETINGTYP';ftypes[7]='radio';fnames[1]='FNAME';ftypes[1]='text';fnames[2]='LNAME';ftypes[2]='text';fnames[6]='COMPNAME';ftypes[6]='text';fnames[0]='EMAIL';ftypes[0]='email';fnames[4]='PHONE';ftypes[4]='phone';fnames[3]='ADDRESS';ftypes[3]='address';fnames[5]='BIRTHDAY';ftypes[5]='birthday';}(jQuery));var $mcj = jQuery.noConflict(true);</script>
             </div>`,
              }}
            />
          </div>
        </div>
      </div>

      <div className={s.homeFooter}>
        <footer className={s.root}>
          <div className={s.footerTop}>
            <div className={s.container}>
              <div className={s.footerRow}>
                <div className={s.footerMenu}>
                  <h5>Company</h5>

                  <ul className={s.footerMenuList}>
                    <li className={s.footerMenuItem}>
                      <Link to="/about">About Us</Link>
                    </li>
                    <li className={s.footerMenuItem}>
                      <Link to="/services">What We Do</Link>
                    </li>
                    <li className={s.footerMenuItem}>
                      <Link to="/work">Case Studies</Link>
                    </li>
                    <li className={s.footerMenuItem}>
                      <Link to="/blog">Blog</Link>
                    </li>
                    <li className={s.footerMenuItem}>
                      <Link to="/careers">Careers</Link>
                    </li>
                    <li className={s.footerMenuItem}>
                      <Link to="/contact">Contact Us</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className={s.footerLine} />
              <div className={s.copyrightContainer}>
                <Copyright />
              </div>
            </div>
          </div>
        </footer>
      </div>
    </div>
  </Layout>
);

export default DataStrategyGenAIBusinessGrowth;
